<template>
  <div class="home-page fill-height">

    <v-row align="center" justify="center" class="fill-height primary" style="margin-top: 0 !important;">

      <v-card
          class="mx-auto"
          max-width="400"
          rounded="xl">
        <v-container>

          <v-row align="center" justify="center" class="mt-2">
            <v-col align="center" justify="center" cols="12" md="12" lg="12">
              <v-img
                  alt="Aliados QR"
                  src="@/assets/logo_biocredit.png"
                  width="160"
              />
            </v-col>

            <v-col cols="12" md="12" lg="12">

              <div class="px-10 pb-6">
                <v-form
                    ref="form"
                    lazy-validation
                    @submit.prevent="changePassword">

                  <v-text-field
                      ref="new-password"
                      v-model="newPassword"
                      :rules="[v => !!v || 'Ingresa la contraseña nueva']"
                      type="password"
                      label="Contraseña Nueva"
                      required
                      outlined
                      dense
                      color="primary"
                      class="input-field"
                  ></v-text-field>

                  <v-text-field
                      ref="password"
                      v-model="password"
                      :rules="[v => !!v || 'Ingresa la contraseña nueva']"
                      type="password"
                      label="Repite la Contraseña Nueva"
                      required
                      outlined
                      dense
                      color="primary"
                      class="input-field"
                  ></v-text-field>


                  <v-layout justify-center>
                    <v-card-actions>
                      <v-btn
                          :loading="loading"
                          :disabled="loading"
                          color="primary"
                          type="submit"
                          :rounded="true"
                          class="px-15 text-capitalize vardi-button">
                        Cambiar
                      </v-btn>
                    </v-card-actions>
                  </v-layout>

                </v-form>
              </div>


            </v-col>
          </v-row>

        </v-container>
      </v-card>

    </v-row>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>
  </div>

</template>

<script>
import OnboardingModal from "@/components/Layouts/OnboardingModal";
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";


export default {
  name: 'ChangePassword',
  components: {OnboardingModal, LeftSideImage},
  data() {
    return {
      email: null,
      password: '',
      newPassword: '',

      document_type: '',
      document_id: '',
      coupon: '',
      dialog: false,
      promoCode: false,
      loading: false,
      settings: null,
      document_types: [],
      snackbar: false,
      timeout: 3000,
      snackbarMessage: '',
      dialogResetPassword: false,
    }
  },
  methods: {
    changePassword() {

      if(this.password == '' && this.newPassword == '') {
        this.snackbarMessage = 'Se deben llener todos los campos';
        this.snackbar = true;
      } else if (this.password.length < 6 || this.newPassword.length < 6) {
        this.snackbarMessage = 'La contraseña debe ser de mas de 6 digitos';
        this.snackbar = true;
      } else {

        this.loading = true;

        if(this.password == this.newPassword) {

          api.post(constants.endPoints.changePassword, {
            user_id: this.$route.params.user_id,
            password: this.password,
          }).then(function (response) {

            console.log('change password: ', response);
            this.loading = false;
            this.snackbarMessage = 'Se ha enviado un correo electronico con las indicaciones para cambiar la contraseña';
            this.snackbar = true;

            this.$router.push('/');

          }.bind(this)).catch(function (error) {
            let errors = error.response.data;
            console.log('errors: ', error, error.response);
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              console.log(key + ' : ' + value);
              message = message + value + '<br>';
            }
            this.snackbarMessage = 'Hubo un problema, intentalo más tarde nuevamente';
            this.snackbar = true;
            this.loading = false;

            this.$refs['password'].error = true;
            this.$refs['password'].errorMessages = ['La contraseña es incorrecta'];

          }.bind(this))

        } else {
          this.snackbarMessage = 'Las contraseñas no son iguales';
          this.snackbar = true;
        }
      }

    },
  },
  mounted() {

  },
  created() {
    console.log(this.$route.params.user_id)

  }
}
</script>

<style >

.v-messages__wrapper {
  float: right;
}

.v-application .text-caption {
  letter-spacing: 0 !important;
  line-height: 0.8rem !important;
  font-size: 0.65rem !important;
}

.terms-link {
  display: block;
}

.v-card__title {
  word-break: inherit;
}

.home-title {
  font-size: 2.5em;
  line-height: 1;
}

.card-subtitle {
  font-size: 22px;
  line-height: 1;
}

.home-image {
  height: 100px;
}

@media (max-width: 600px) {
  .home-title {
    font-size: 2em;
    line-height: 1;
  }

  .card-subtitle {
    font-size: 18px;
    line-height: 1;
  }

  .home-image {
    height: 140px;
  }
}
</style>
